import React, { useContext } from 'react';
import { ScrollContext } from '../../../../../App';
import { ButtonFilled } from '../../../../common/Button/Button';

import './Title.scss';

export const Title = () => {
	const { scrollToAppointmentSection } = useContext(ScrollContext);

	return (
		<div className='title-container bg-gradient-linear-main pt-main ph-main'>
			<img
				draggable={false}
				className='img-arrow'
				src='/assets/images/VEMAR_2.png'
				alt=''
			/>
			<img
				draggable={false}
				className='img-cross'
				src='/assets/images/VEMAR_7.png'
				alt=''
			/>
			<section className='title-section'>
				<div className='title-row'>
					<h1 className='title h-white'>
						Приватна
						<br />
						медична клініка
					</h1>
				</div>
				<h3 className='subtitle'>
					Комфорт турбуватися
					<br />
					про своє здоровʼя
				</h3>
				<div className='button-row'>
					<ButtonFilled
						text='Записатися'
						filled={true}
						onClick={scrollToAppointmentSection}
					/>
				</div>
			</section>
		</div>
	);
};
