import React, { useState } from 'react';
import { PhoneInput } from '../../../../common/PhoneInput/PhoneInput';
import { ButtonFilled } from '../../../../common/Button/Button';

import './ConsultingForm.scss';
import { postConsulting } from '../../../../../lib/services';

export const ConsultingForm = () => {
	const [phoneUnmasked, setPhoneUnmasked] = useState('');
	const [phoneValue, setPhoneValue] = useState('');
	const [isPhoneComplete, setIsPhoneComplete] = useState(false);

	const resetForm = () => {
		setPhoneValue('');
	};

	const handleSubmit = async () => {
		try {
			await postConsulting(phoneUnmasked);
		} catch (e) {
			console.log(e);
		} finally {
			resetForm();
		}
	};

	return (
		<div className='ph-main no-ph-main-768'>
			<div className='consulting'>
				<h2 className='h-white'>Отримати консультацію</h2>
				<div className='phone-section'>
					<PhoneInput
						value={phoneValue}
						onCompletedChange={setIsPhoneComplete}
						onValueChange={setPhoneValue}
						onUnmaskedValueChange={setPhoneUnmasked}
					/>
					<ButtonFilled
						onClick={handleSubmit}
						disabled={!isPhoneComplete}
						text='Записатися'
					/>
				</div>
			</div>
		</div>
	);
};
