import React, { useContext } from 'react';
import { ScrollContext } from '../../../../../App';
import { ButtonFilled } from '../../../../common/Button/Button';

import './About.scss';

export const About = () => {
	const { scrollToAppointmentSection } = useContext(ScrollContext);

	return (
		<section className='about-home'>
			<div className='about-home-img-wrapper'>
				<img draggable={false} src='/assets/images/about_img.png' alt='' />
			</div>
			<div className='about-home-info ph-main'>
				<div className='about-home-info-grid'>
					<h2 className='title'>
						Приватна
						<br />
						медична клініка
					</h2>
					<p className='text'>
						Приватна медична клініка «Вемар» була створена у червні 1998 року як
						приватний спеціалізований центр, що надає комплекс медичних послуг у
						галузі гінекології та урології. З того часу протягом більш ніж 20
						років ми успішно надаємо медичні послуги мешканцям Києва і не
						тільки.
					</p>
					<div className='about-home-info-btn'>
						<ButtonFilled
							text='Записатися'
							onClick={scrollToAppointmentSection}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};
