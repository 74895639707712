import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Doctors } from './components/pages/Doctors/Doctors';
import { Home } from './components/pages/Home/Home';
import { Services } from './components/pages/Services/Services';
import { Header } from './components/common/Header/Header';
import { Appointment } from './components/common/Appointment/Appointment';
import { Footer } from './components/common/Footer/Footer';
import { createContext, useRef } from 'react';
import { Contacts } from './components/pages/Contacts/Contacts';
import { Prices } from './components/pages/Prices/Prices';
import { Blog } from './components/pages/Blog/Blog';

export const ScrollContext = createContext({});

function App() {
	const appointmentSectionRef = useRef(null);

	const scrollToAppointmentSection = () => {
		if (appointmentSectionRef.current) {
			appointmentSectionRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	};

	return (
		<BrowserRouter>
			<div className='page'>
				<Header />
				<ScrollContext.Provider value={{ scrollToAppointmentSection }}>
					<Routes>
						<Route path='/' exact element={<Home />} />
						<Route path='doctors/:section?' exact element={<Doctors />} />
						<Route
							path='services/:categoryId?/:serviceId?'
							exact
							element={<Services />}
						/>
						<Route path='prices' exact element={<Prices />} />
						<Route path='contacts' exact element={<Contacts />} />
						<Route path='blog' exact element={<Blog />} />
						<Route path='*' element={<Navigate to='/' />} />
					</Routes>
					<div>
						<Appointment ref={appointmentSectionRef} />
						<Footer />
					</div>
				</ScrollContext.Provider>
			</div>
		</BrowserRouter>
	);
}

export default App;
