import React from 'react';

import './ReviewCard.scss';

export const PhotoReviewCard = ({ text, name, photoFileName }) => {
	return (
		<div className='photo-review-card review-card-shadow'>
			<div className='photo-review-card-img-wrapper'>
				<img draggable={false} src={`/assets/images/${photoFileName}`} alt='' />
			</div>
			<ReviewCard text={text} name={name} />
		</div>
	);
};

export const ReviewCard = ({ text, dateStr = '', name }) => {
	const dateO = new Date(dateStr);

	const correctDate = !isNaN(dateO.getHours());

	const formatTimeUnit = (time) => {
		if (time <= 0) return '00';
		return time.toString();
	};

	const date = dateO.toLocaleDateString();
	const time = ` о ${formatTimeUnit(dateO.getHours())}:${formatTimeUnit(
		dateO.getMinutes()
	)}`;

	return (
		<div className='review-card'>
			<div className='review-card-header'>
				<h2>{name}</h2>
				{correctDate && (
					<span className='review-card-header-date'>
						{date}
						<span className='review-card-header-time'>{time}</span>
					</span>
				)}
			</div>
			<p>{text}</p>
		</div>
	);
};
