import { useNavigate } from 'react-router-dom';
import reviewsData from '../../../../../data/reviews.json';
import {
	PhotoReviewCard,
	ReviewCard,
} from '../../../../common/ReviewCard/ReviewCard';
import React from 'react';
import { TitledSection } from '../TitledSection/TitledSection';

import './Reviews.scss';

export const Reviews = () => {
	const navigate = useNavigate();

	const photoCardData = reviewsData[0];
	let photoCard = null;
	if (photoCardData) {
		photoCard = (
			<PhotoReviewCard
				text={photoCardData.text}
				name={photoCardData.name}
				photoFileName={photoCardData.img}
			/>
		);
	}

	const goToReviews = () => {
		navigate('/doctors/reviews');
	};

	const cards = reviewsData.slice(1, 3).map((r) => (
		<React.Fragment key={r.date}>
			<ReviewCard text={r.text} name={r.name} dateStr={r.date} />{' '}
		</React.Fragment>
	));

	return (
		<div className='reviews-home'>
			<TitledSection
				title='Відгуки'
				btnText='Всі відгуки'
				onClick={goToReviews}
			>
				<div className='reviews-home-section'>
					{photoCard}
					<div className='reviews-home-grid'>{cards}</div>
				</div>
			</TitledSection>
		</div>
	);
};
