import React from 'react';
import { useNavigate } from 'react-router-dom';
import doctorsData from '../../../../../data/doctors.json';
import { TitledSection } from '../TitledSection/TitledSection';

import './Doctors.scss';

const DoctorCard = ({ name, imgFileName, expYears }) => {
	const lines = name.split(/\s+/g);

	const years = parseInt(expYears);
	const text = expYears.replace(/\d+\s+/, '');

	return (
		<div className='doctor-card'>
			<div className='doctor-card-img-wrapper'>
				<img
					draggable={false}
					className='doctor-card-img'
					src={`/assets/images/${imgFileName}`}
					alt={name}
				/>
			</div>
			<div className='doctor-card-info'>
				<h2>
					{lines.map((line, index) => (
						<React.Fragment key={index}>
							{line}
							{index !== lines.length - 1 && <br />}
						</React.Fragment>
					))}
				</h2>
				<div className='doctor-card-info-exp'>
					<h1 className='doctor-card-info-exp-years text-gradient-linear-dark'>
						{years}
					</h1>
					<span>{text}</span>
				</div>
			</div>
		</div>
	);
};

export const Doctors = () => {
	const navigate = useNavigate();

	const goToReviews = () => {
		navigate('/doctors');
	};

	const cards = doctorsData.map((d) => (
		<React.Fragment key={d.name}>
			<DoctorCard expYears={d.exp_years} imgFileName={d.img} name={d.name} />
		</React.Fragment>
	));

	return (
		<div className='doctors-home'>
			<img
				draggable={false}
				className='doctors-home-bg-img'
				src='/assets/images/VEMAR_9.png'
				alt=''
			/>
			<TitledSection title='Лікарі' btnText='Всі лікарі' onClick={goToReviews}>
				<div className='doctors-home-section'>
					<div className='doctors-home-grid'>{cards}</div>
					<p className='doctors-home-section-text'>
						Наші лікарі вже багато років працюють у сфері гінекології та
						урології, постійно вдосконалюються та прагнуть знаходити
						індивідуальний підхід до кожного клієнта. Нам важливо, щоб все було
						на найвищому рівні.
					</p>
				</div>
			</TitledSection>
		</div>
	);
};
