import React from 'react';

import './Logo.css';
import { useNavigate } from 'react-router-dom';

export const Logo = ({ isDark }) => {
	const navigate = useNavigate();
	const logoName = isDark ? 'logo_dark' : 'logo_light';
	return (
		<img
			draggable={false}
			onClick={() => navigate('/')}
			className={`logo ${isDark ? 'logo-sm' : ''}`}
			src={`/assets/images/${logoName}.svg`}
			alt=''
		/>
	);
};
