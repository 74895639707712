import React, { forwardRef, useState } from 'react';
import { PhoneInput } from '../PhoneInput/PhoneInput';
import { ButtonFilled } from '../Button/Button';

import './Appointment.scss';
import { postAppointment } from '../../../lib/services';

const AppointmentForm = () => {
	const [name, setName] = useState('');
	const [phoneUnmasked, setPhoneUnmasked] = useState('');
	const [phoneValue, setPhoneValue] = useState('');
	const [isPhoneComplete, setIsPhoneComplete] = useState(false);
	const [message, setMessage] = useState('');

	const onChangeHandler = (setter) => (e) => {
		setter(e.target.value);
	};

	const resetForm = () => {
		setName('');
		setMessage('');
		setPhoneValue('');
	};

	const submitHandler = async () => {
		try {
			await postAppointment(name, phoneUnmasked, message);
		} catch (e) {
			console.log(e);
		} finally {
			resetForm();
		}
	};

	return (
		<div>
			<div className='appointment-form'>
				<input
					onChange={onChangeHandler(setName)}
					value={name}
					className='styled-input'
					type='text'
					placeholder='Ваше Імʼя'
				/>
				<PhoneInput
					value={phoneValue}
					onValueChange={setPhoneValue}
					onUnmaskedValueChange={setPhoneUnmasked}
					onCompletedChange={setIsPhoneComplete}
				/>
				<textarea
					value={message}
					onChange={onChangeHandler(setMessage)}
					className='styled-input'
					placeholder='Ваше повідомлення'
				/>
			</div>
			<div className='appointment-form-btn-row'>
				<ButtonFilled
					onClick={submitHandler}
					disabled={!(name && isPhoneComplete && message)}
					text='Записатися'
				/>
			</div>
		</div>
	);
};

export const Appointment = forwardRef((_, ref) => {
	return (
		<div ref={ref} className='appointment ph-main'>
			<img
				draggable={false}
				className='appointment-bg-img'
				src='/assets/images/VEMAR_5.png'
				alt=''
			/>
			<h2 className='h-white'>Записатися на прийом</h2>
			<p>Ми зателефонуємо вам!</p>
			<div className='appointment-grid'>
				<AppointmentForm />
			</div>
		</div>
	);
});
