import { ButtonOutline } from '../../../../common/Button/Button';
import React from 'react';

import './TitledSection.scss';

export const TitledSection = ({ title, onClick, btnText, children }) => {
	return (
		<div className='titled-section ph-main'>
			<h2 className='titled-section-title'>{title}</h2>
			<ButtonOutline isLight={false} text={btnText} onClick={onClick} />
			<section className='titled-section-section'>{children}</section>
		</div>
	);
};
