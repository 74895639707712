import { useNavigate } from 'react-router-dom';
import servicesData from '../../../../../data/services.json';
import React from 'react';
import { TitledSection } from '../TitledSection/TitledSection';
import { ConsultingForm } from '../ConsultingForm/ConsultingForm';

import './Services.scss';

const ServicesCard = ({ servicesCategory }) => {
	const navigate = useNavigate();

	const goToService = (serviceId) => {
		navigate(`/services/${servicesCategory.id}/${serviceId}`);
	};

	return (
		<div className='services-card'>
			<h4 className='services-card-title text-gradient-linear'>
				{servicesCategory.title}
			</h4>
			<ul>
				{servicesCategory.services.map((s) => (
					<li onClick={() => goToService(s.id)} key={s.name}>
						{s.name}
					</li>
				))}
			</ul>
		</div>
	);
};

export const Services = () => {
	const navigate = useNavigate();

	const goToServices = () => {
		navigate('/services');
	};

	const services = servicesData.map((s, i) => (
		<React.Fragment key={i}>
			<ServicesCard servicesCategory={s} />
		</React.Fragment>
	));

	return (
		<div className='services-home'>
			<img
				draggable={false}
				className='services-img'
				src='/assets/images/VEMAR_6.png'
				alt=''
			/>
			<TitledSection
				title='Послуги'
				btnText='Всі послуги'
				onClick={goToServices}
			>
				<div className='services-grid'>{services}</div>
			</TitledSection>
			<ConsultingForm />
		</div>
	);
};
