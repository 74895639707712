import React, { createContext, useContext, useEffect, useState } from 'react';
import { ButtonFilled } from '../../common/Button/Button';
import { ScrollContext } from '../../../App';
import pricesData from '../../../data/prices.json';

import './Prices.scss';

const PricesContext = createContext({});

const PriceItem = ({ price }) => {
	return (
		<div className='prices-list-item'>
			<div className='leaders'></div>
			<div className='prices-list-item-title'>
				<div>
					<span className='name'>{price.name}</span>
				</div>
				<div>
					<span className='price'>{price.price}</span>
				</div>
			</div>
		</div>
	);
};

const PricesList = ({ prices }) => {
	const { search } = useContext(PricesContext);
	const priceItems = prices
		.filter((p) => p.name.toLowerCase().includes(search.toLowerCase()))
		.map((p, i) => (
			<React.Fragment key={i}>
				<PriceItem price={p} />
			</React.Fragment>
		));

	return <div className='prices-list'>{priceItems}</div>;
};

const PricesAccordionItem = ({ item }) => {
	const [isOpen, setIsOpen] = useState(true);
	const { search } = useContext(PricesContext);

	useEffect(() => {
		setIsOpen(true);
	}, [search]);

	const onClickHandler = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className='prices-accordion-item'>
			<h2 onClick={onClickHandler}>{item.title}</h2>
			<div className={`prices-accordion-item-body ${isOpen ? 'open' : ''}`}>
				<div className='prices-accordion-item-body-content'>
					<PricesList prices={item.prices} />
				</div>
			</div>
		</div>
	);
};

const PricesAccordion = ({ items }) => {
	const priceCategories = items.map((item, i) => (
		<React.Fragment key={i}>
			<PricesAccordionItem item={item} />
		</React.Fragment>
	));

	return (
		<div className='prices-accordion ph-main no-ph-main-320'>
			{priceCategories}
		</div>
	);
};

export const Prices = () => {
	const { scrollToAppointmentSection } = useContext(ScrollContext);

	const [search, setSearch] = useState('');

	const inputChangeHandler = (e) => {
		setSearch(e.target.value);
	};

	return (
		<PricesContext.Provider value={{ search }}>
			<div className='page-content mt-main prices-page pv-page-content'>
				<div className='ph-main'>
					<img
						draggable={false}
						className='img-1'
						src='/assets/images/VEMAR_9.png'
						alt=''
					/>
					<img
						draggable={false}
						className='img-2'
						src='/assets/images/VEMAR_3.png'
						alt=''
					/>
					<img
						draggable={false}
						className='img-3'
						src='/assets/images/VEMAR_8.png'
						alt=''
					/>
					<div>
						<h1 className='titled-section-title'>Ціни</h1>
						<div className='prices-page-text'>
							<p>
								Ціни на послуги в клініці «ВеМар» мають ознайомлювальний
								характер. Ціни на послуги можуть змінюватись. Актуальні ціни
								уточнюйте за телефонами клініки.
							</p>
						</div>
						<div className='prices-search'>
							<input
								onChange={inputChangeHandler}
								className='styled-input'
								type='text'
								placeholder='Пошук'
							/>
							<ButtonFilled
								text='Записатися'
								onClick={scrollToAppointmentSection}
							/>
						</div>
					</div>
				</div>
				<PricesAccordion items={pricesData} />
			</div>
		</PricesContext.Provider>
	);
};
