import React from 'react';
import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import contactsData from '../../../data/contacts.json';

import './Contacts.scss';

export const Contacts = () => {
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
	});

	return (
		<div className='page-content mt-main ph-main contacts-page pv-page-content'>
			<h1 className='titled-section-title'>Контакти</h1>

			{isLoaded && (
				<GoogleMap
					zoom={15}
					center={contactsData.address.coordinates}
					mapContainerClassName='map-container'
				>
					<MarkerF position={contactsData.address.coordinates} />
				</GoogleMap>
			)}
			<div className='contacts-grid'>
				<div className='contact'>
					<h4 className='text-gradient-linear'>Адреса:</h4>
					<p>
						{contactsData.address.city}
						<br />
						{contactsData.address.location}
					</p>
				</div>
				<div className='contact'>
					<h4 className='text-gradient-linear'>Телефони:</h4>
					<p>{contactsData.tels[0]}</p>
					<p>{contactsData.tels[1]}</p>
					<p>{contactsData.tels[2]}</p>
				</div>
				<div className='contact'>
					<h4 className='text-gradient-linear'>Графік роботи:</h4>
					<p>
						{contactsData.schedule.page.week_days}
						<br />
						{contactsData.schedule.page.week_hours}
					</p>
					<p>
						{contactsData.schedule.page.weekend_days}
						<br />
						{contactsData.schedule.page.weekend_hours}
					</p>
					<p>
						E-mail:
						<br />
						<a href={`mailto:${contactsData.email}`}>{contactsData.email}</a>
					</p>
				</div>
			</div>
		</div>
	);
};
