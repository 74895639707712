import React from 'react';

import './Certificates.scss';

export const Certificates = () => {
	return (
		<div className='certificates'>
			<div className='certificates-info'>
				<h2 className='text-gradient-linear'>Сертифікати</h2>
				<p>
					Сертифікати, здобуті клінікою Вемар, підтверджують якість наших послуг
					та кваліфікованість наших лікарів
				</p>
			</div>
			<div className='certificates-gallery'>
				<div className='certificates-gallery-shadow'></div>
				<div className='certificates-gallery-grid'>
					<img draggable={false} src='/assets/images/005-745x1024.png' alt='' />
					<img draggable={false} src='/assets/images/004-745x1024.png' alt='' />
					<img draggable={false} src='/assets/images/003-745x1024.png' alt='' />
					<img draggable={false} src='/assets/images/002-745x1024.png' alt='' />
				</div>
			</div>
		</div>
	);
};
