import React from 'react';
import { Title } from './components/Title/Title';
import { About } from './components/About/About';
import { Services } from './components/Services/Services';
import { Doctors } from './components/Doctors/Doctors';
import { Certificates } from './components/Certificates/Certificates';
import { Reviews } from './components/Reviews/Reviews';

export const Home = () => {
	return (
		<>
			<Title />
			<About />
			<Services />
			<Doctors />
			<Certificates />
			<Reviews />
		</>
	);
};
