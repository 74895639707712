import React, { createContext, useContext, useEffect, useState } from 'react';
import servicesData from '../../../data/services.json';
import { generateRandomKey } from '../../../lib/utils';
import { useParams } from 'react-router-dom';

import './Services.scss';

const ServicesContext = createContext({});

const Service = ({ service }) => {
	const { selectedService, setSelectedService } = useContext(ServicesContext);

	return (
		<li
			onClick={() => setSelectedService(service)}
			className={`service-name ${
				selectedService.id === service.id ? 'open' : ''
			}`}
		>
			{service.name}
		</li>
	);
};

const ServicesList = ({ services }) => {
	const serviceItems = services.map((s, i) => (
		<React.Fragment key={i}>
			<Service service={s} />
		</React.Fragment>
	));

	return (
		<div className='services-accordion-item-body-content'>
			<ul className='services-accordion-item-body-list'>{serviceItems}</ul>
		</div>
	);
};

const ServicesAccordionItem = ({ item }) => {
	const checkSmallScreen = () =>
		window.innerWidth > 480 && window.innerWidth <= 768;

	const serviceIsFromCurrentCategory = () =>
		item.id === selectedService.category_id;

	const isSelected = () => selectedCategory.id === item.id;

	const { selectedService, selectedCategory, setSelectedCategory } =
		useContext(ServicesContext);
	const [isOpen, setIsOpen] = useState(isSelected());
	const [isOpenInfo, setIsOpenInfo] = useState(serviceIsFromCurrentCategory());
	const [isSmallScreen, setIsSmallScreen] = useState(checkSmallScreen());

	const onClickHandler = () => {
		if (!isSmallScreen) {
			setIsOpen(!isOpen);
		}
		setSelectedCategory(item);
	};

	const screenSizeChangeHandler = () => {
		setIsSmallScreen(checkSmallScreen(window.innerWidth));
	};

	useEffect(() => {
		setIsOpen(isSelected());
	}, [isSmallScreen]);

	useEffect(() => {
		if (isSmallScreen) {
			setIsOpen(isSelected());
		}
	}, [selectedCategory]);

	useEffect(() => {
		setIsOpenInfo(serviceIsFromCurrentCategory());
	}, [selectedService]);

	useEffect(() => {
		window.addEventListener('resize', screenSizeChangeHandler);
		return () => {
			window.removeEventListener('resize', screenSizeChangeHandler);
		};
	}, []);

	return (
		<div className='services-accordion-item'>
			<div onClick={onClickHandler} className='services-accordion-item-header'>
				<div className='d-flex justify-content-between align-items-center'>
					<h4 className={`header ${isOpen ? 'text-gradient-linear' : ''}`}>
						{item.title}
					</h4>
					<div className={`plus-marker ${isOpen ? 'open' : ''}`}>+</div>
				</div>
			</div>
			<div className={`services-accordion-item-body ${isOpen ? 'open' : ''}`}>
				<div className='content-wrapper'>
					<ServicesList services={item.services} />
					{isOpenInfo && (
						<div className='services-accordion-item-body-info'>
							<ServiceInfo service={selectedService} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const ServicesAccordion = ({ items }) => {
	const { selectedCategory } = useContext(ServicesContext);

	const itemViews = items.map((item, i) => (
		<React.Fragment key={i}>
			<ServicesAccordionItem item={item} />
		</React.Fragment>
	));

	return (
		<div className='services-accordion'>
			<div>{itemViews}</div>
			<div className='services-accordion-side-panel'>
				<ServicesList services={selectedCategory.services} />
			</div>
		</div>
	);
};

const ServiceInfo = ({ service }) => {
	const makePar = (text) => (
		<p key={generateRandomKey()} style={{ whiteSpace: 'pre-wrap' }}>
			{text}
		</p>
	);

	const makeList = (text) => (
		<ul key={generateRandomKey()}>
			{text
				.replace(/<\/?ul>/g, '')
				.split('\n')
				.map((li, i) => (
					<li key={i}>{li}</li>
				))}
		</ul>
	);

	const info = service.info
		?.split(/(<ul>.*?<\/ul>)/gms)
		.map((t) => (t.startsWith('<ul>') ? makeList(t) : makePar(t)));

	return (
		<div className='service-info'>
			<h2>{service.name}</h2>
			{info}
		</div>
	);
};

export const Services = () => {
	const { categoryId, serviceId } = useParams();

	const findCategory = (categoryId) =>
		servicesData.find((c) => c.id === categoryId);

	const findService = (category, serviceId) =>
		category?.services.find((s) => s.id === serviceId);

	const category = findCategory(+categoryId);
	const service = findService(category, +serviceId);
	const paramsCorrect = Boolean(service && category);

	const initialCategory = findCategory(1);
	const initialService = findService(initialCategory, 1);

	const [selectedCategory, setSelectedCategory] = useState(
		paramsCorrect ? category : initialCategory
	);

	const [selectedService, setSelectedService] = useState(
		paramsCorrect ? service : initialService
	);

	return (
		<ServicesContext.Provider
			value={{
				selectedService,
				setSelectedService,
				selectedCategory,
				setSelectedCategory,
			}}
		>
			<div className='page-content services-page mt-main ph-main pv-page-content'>
				<img
					draggable={false}
					className='img-1'
					src='/assets/images/VEMAR_4.png'
					alt=''
				/>
				<img
					draggable={false}
					className='img-2'
					src='/assets/images/VEMAR_3.png'
					alt=''
				/>
				<div className='position-relative'>
					<h1 className='titled-section-title'>Послуги</h1>
					<div className='services-grid'>
						<ServicesAccordion items={servicesData} />
						<ServiceInfo service={selectedService} />
					</div>
				</div>
			</div>
		</ServicesContext.Provider>
	);
};
