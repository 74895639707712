import React from 'react';

import './Button.scss';

export const ButtonFilled = ({ text, onClick, disabled = false }) => {
	return (
		<button
			disabled={disabled}
			onClick={onClick}
			className='main-button filled light'
		>
			{text}
		</button>
	);
};

export const ButtonOutline = ({ text, onClick, isLight }) => {
	return (
		<button
			onClick={onClick}
			className={`main-button ${isLight ? 'light' : 'dark'}`}
		>
			{text}
		</button>
	);
};
