import { APPOINTMENT_URL, CONSULTING_URL } from './constants';

export const postAppointment = (name, phone, message) => {
	return fetch(APPOINTMENT_URL, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ name, phone, message }),
	});
};

export const postConsulting = (phone) => {
	return fetch(CONSULTING_URL, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ phone }),
	});
};
