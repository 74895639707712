import React, { useEffect, useRef } from 'react';
import doctorsData from '../../../data/doctors.json';
import reviewsData from '../../../data/reviews.json';

import './Doctors.scss';
import { ReviewCard } from '../../common/ReviewCard/ReviewCard';
import { useParams } from 'react-router-dom';
import { generateRandomKey } from '../../../lib/utils';

const DoctorCard = ({ name, expYears, text, imgFileName }) => {
	const lines = name.split(/\s+/g);

	return (
		<div className='doctor-card'>
			<div className='doctor-card-img-wrapper'>
				<img
					draggable={false}
					src={`/assets/images/${imgFileName}`}
					alt={name}
				/>
			</div>
			<div className='doctor-card-info'>
				<h2 className='text-gradient-linear'>
					{lines.map((line, index) => (
						<React.Fragment key={index}>
							{line}
							{index !== lines.length - 1 && <br />}
						</React.Fragment>
					))}
				</h2>
				<div className='doctor-card-info-exp'>{expYears}</div>
				<p className='doctor-card-info-text'>{text}</p>
			</div>
		</div>
	);
};

export const Doctors = () => {
	const reviewsRef = useRef(null);
	const { section } = useParams();

	const doctorCards = doctorsData.map((d) => (
		<React.Fragment key={generateRandomKey()}>
			<DoctorCard
				name={d.name}
				text={d.description}
				expYears={d.exp_years}
				imgFileName={d.img}
			/>
		</React.Fragment>
	));

	useEffect(() => {
		if (section === 'reviews' && reviewsRef.current) {
			reviewsRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}
	}, []);

	const reviewCards = reviewsData.map((r) => (
		<React.Fragment key={r.date}>
			<ReviewCard name={r.name} text={r.text} dateStr={r.date} />
		</React.Fragment>
	));

	return (
		<div className='page-content mt-main ph-main doctors-page pv-page-content'>
			<div className='doctors'>
				<h1 className='titled-section-title'>Лікарі</h1>
				<div className='doctors-section'>
					<div className='doctors-col'>{doctorCards}</div>
					<p className='doctors-text'>
						Наші лікарі вже багато років працюють у сфері гінекології та
						урології, постійно вдосконалюються та прагнуть знаходити
						індивідуальний підхід до кожного клієнта. Нам важливо, щоб все було
						на найвищому рівні.
					</p>
				</div>
			</div>
			<div ref={reviewsRef} className='reviews'>
				<h1 className='titled-section-title'>Відгуки</h1>
				<div className='reviews-grid'>{reviewCards}</div>
			</div>
		</div>
	);
};
