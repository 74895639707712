import React from 'react';
import { Logo } from '../Logo/Logo';
import { Link } from 'react-router-dom';
import contactsData from '../../../data/contacts.json';

import './Footer.scss';

export const Footer = () => {
	return (
		<div className='ph-main footer'>
			<Logo isDark={false} />
			<ul>
				<Link to='/'>
					<li className='text-gradient-linear-hover'>Головна</li>
				</Link>
				<Link to='/doctors'>
					<li className='text-gradient-linear-hover'>Лікарі</li>
				</Link>
				<Link to='/services'>
					<li className='text-gradient-linear-hover'>Послуги</li>
				</Link>
				<Link to='/prices'>
					<li className='text-gradient-linear-hover'>Ціни</li>
				</Link>
				<Link to='/blog'>
					<li className='text-gradient-linear-hover'>Блог</li>
				</Link>
				<Link to='/contacts'>
					<li className='text-gradient-linear-hover'>Контакти</li>
				</Link>
			</ul>
			<div className='footer-info'>
				<div>
					<h4 className='fw-bold text-gradient-linear'>Режим роботи:</h4>
					<p>
						{contactsData.schedule.footer.week_days}
						<br />
						{contactsData.schedule.footer.week_hours}
					</p>
					<p>
						{contactsData.schedule.footer.weekend_days}
						<br />
						{contactsData.schedule.footer.weekend_hours}
					</p>
				</div>
				<div>
					<h4 className='fw-bold text-gradient-linear'>Контакти</h4>
					<p>{contactsData.tels[0]}</p>
					<p>{contactsData.tels[1]}</p>
					<p>{contactsData.tels[2]}</p>
				</div>
			</div>
		</div>
	);
};
