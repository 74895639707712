import React, { useEffect, useState } from 'react';
import { useIMask } from 'react-imask';

export const PhoneInput = ({
	value = '',
	onValueChange = () => {},
	onUnmaskedValueChange = () => {},
	onCompletedChange = () => {},
}) => {
	const [isEmptyTel, setIsEmptyTel] = useState(true);

	const options = {
		mask: '{+38}({\\0}00)000-00-00',
		lazy: false,
	};

	const minLen = 4;
	const maxLen = 13;

	const { ref, unmaskedValue, value: innerValue, setValue } = useIMask(options);

	useEffect(() => {
		onUnmaskedValueChange(unmaskedValue);
		setIsEmptyTel(unmaskedValue.length <= minLen);
		if (unmaskedValue.length < maxLen) {
			onCompletedChange(false);
		} else {
			onCompletedChange(true);
		}
	}, [unmaskedValue]);

	useEffect(() => {
		setValue(value);
	}, [value]);

	useEffect(() => {
		onValueChange(innerValue);
	}, [innerValue]);

	return (
		<input
			className={`styled-input ${isEmptyTel ? 'empty' : ''}`}
			type='tel'
			ref={ref}
		/>
	);
};
