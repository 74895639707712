import React, { useEffect, useState } from 'react';
import { Logo } from '../Logo/Logo';
import { Link, useLocation } from 'react-router-dom';
import contactsData from '../../../data/contacts.json';

import './Header.scss';

const pageTitlesMap = {
	home: 'Вемар | Приватна клініка',
	blog: 'Блог | Вемар',
	contacts: 'Контакти | Вемар',
	doctors: 'Лікарі | Вемар',
	prices: 'Ціни | Вемар',
	services: 'Послуги | Вемар',
};

export const Header = () => {
	const location = useLocation();

	const [isOpen, setIsOpen] = useState(false);

	const closeMenu = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		window.addEventListener('resize', closeMenu);
		return () => {
			window.removeEventListener('resize', closeMenu);
		};
	}, []);

	const setNewTitle = () => {
		const path = location.pathname.replace(/\//, '');
		const key = Object.keys(pageTitlesMap).find((t) => path.startsWith(t));
		document.title = pageTitlesMap[key] || pageTitlesMap['home'];
	};

	useEffect(() => {
		closeMenu();
		window.scrollTo(0, 0);
		setNewTitle();
	}, [location]);

	const links = (
		<ul className='nav-bar-items'>
			<Link to='/'>
				<li>Головна</li>
			</Link>
			<Link to='/doctors'>
				<li>Лікарі</li>
			</Link>
			<Link to='/services'>
				<li>Послуги</li>
			</Link>
			<Link to='/prices'>
				<li>Ціни</li>
			</Link>
			<Link to='/blog'>
				<li>Блог</li>
			</Link>
			<Link to='/contacts'>
				<li>Контакти</li>
			</Link>
		</ul>
	);

	const burger = (
		<div
			className={`hamburger ${isOpen ? 'active' : ''}`}
			onClick={() => setIsOpen(!isOpen)}
		>
			<span className='line'></span>
			<span className='line'></span>
			<span className='line'></span>
		</div>
	);

	return (
		<>
			<div className='nav-bar-bg bg-gradient-linear-main'></div>
			<header className='nav-bar ph-main'>
				<div className='nav-bar-menu'>
					<Logo isDark={true} />
					<div className='nav-bar-section'>
						{links}
						<span className='bar'></span>
						<ul className='nav-bar-items fw-bold'>
							<li>{contactsData.tels[0]}</li>
							<li>{contactsData.tels[1]}</li>
							<li>{contactsData.tels[2]}</li>
						</ul>
					</div>
					{burger}
				</div>
				<div className={`nav-bar-menu-dropdown ${isOpen ? 'active' : ''}`}>
					<div>
						{links}
						<div className='dropdown-shadow'></div>
					</div>
				</div>
			</header>
		</>
	);
};
